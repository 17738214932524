// App.js

import React, { useState } from "react";
import "./App.css";
import a from "../src/imgs/a.jpeg";
import b from "../src/imgs/b.jpeg";
import f from "../src/imgs/f.jpeg";
import xx from "../src/imgs/xummer1.jpg";
import xxx from "../src/imgs/xummer2.jpg";

function App() {
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const handleMouseEnter = (index) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };

  return (
    <div className="flex flex-col sm:h-full App">
      <header
        className={`flex items-center justify-center sm:mx-10 font-bold text-white rounded-lg sm:text-[70px] text-[2rem] sticky-header`}
        style={{
          backgroundImage: `url(${require("../src/imgs/1.jpeg")})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: "100px",
        }}>
        <div>A nEw AgE</div>
      </header>

      <div className="flex flex-col items-center justify-center">
        <div className="grid grid-cols-3 mt-12 ms:mt-0 sm:grid-cols-3">
          {[
            { label: "Alguien Mas", imgSrc: a , url: "https://open.spotify.com/track/32XrPU92TGdDOojslKEkqW?si=0988ddd3275649ce"},
            { label: "B3R$HKA", imgSrc: b , url: "https://open.spotify.com/track/79vPaIf9AaJ6NLtxGTIntJ?si=df2cbf7714f84fc6"},
            { label: "Frenesi", imgSrc: f , url: "https://open.spotify.com/track/5YAZ0lhDcNs0wtIvBhDY91?si=5ce42a501ce5454d"},
          ].map((item, index) => (
            <div
              key={index}
              className={`flex flex-col items-center justify-center ${
                hoveredIndex !== null && hoveredIndex !== index
                  ? "not-hovered"
                  : ""
              } ${hoveredIndex === index ? "hovered rounded-xl" : ""}`}
              onMouseEnter={() => handleMouseEnter(index)}
              onMouseLeave={handleMouseLeave}>
              <p className="flex items-center justify-center sm:h-fit sm:mt-24 sm:mx-10 sm:text-[70px] font-bold text-white rounded w-fit">
                {item.label}
              </p>

              <div
                className={`sm:m-3 sm:mb-10 overflow-hidden rounded-full ${
                  hoveredIndex === index ? "" : ""
                }`}>
                  <a href={item.url}>
                    <img src={item.imgSrc} alt="" className="h-[120px] md:h-[400px] sm:fixed-size-image" />
                  </a>
              </div>
            </div>
          ))}
        </div>
        <div className="w-full m-5">
          <h1 className="font-bold text-[3rem] text-white sm:text-black w-fit mx-auto sm:p-5">
            Xummer
          </h1>
          <div className="grid sm:grid-cols-2 grid-rows-2 sm:w-[1800px] m-5 bg-violet-200 bg-opacity-20 sm:h-[950px] rounded-xl mx-auto">
            <div className="flex flex-col h-[550px] items-center">
              <h1 className="text-[3rem]">
                mUsIc hIstOry
              </h1>
              <p className="p-5 m-5 text-white bg-black md:text-[1.5rem] rounded-lg bg-opacity-20">
                
                Frenesí, una explosión musical que nace tras dos meses de silencio creativo. Una luz inesperada que me guio por el camino de la música. Desde su nacimiento, todo en mi mundo se transformó.
                <br/>
                <br/>
                Vivía la desolación de una mañana tras ser dejado por alguien más. Decidí canalizar ese sentimiento en el estudio. En un abrir y cerrar de ojos, componiendo y dando vida a esta canción, comprendí que la musa interior me impulsaba aún más.
                <br/>
                <br/>
                ¿Has sentido alguna vez que algo debía ser de cierta manera y así fue? Esa fue la experiencia al crear B3R$HKA. Fue como si la canción ya estuviera escrita y yo solo la redescubría. Todo fue tan mágico y electrizante que parecía un acto de magia.
                <br/>
                <br/>
                Descubre la magia de la música en estas tres creaciones: Frenesí, la luz de la inspiración; la intensidad de la devastación en "Alguien Más"; y la asombrosa sensación de que el destino escribía "B3R$HKA". Sumérgete en este mundo mágico de sonidos y emociones.
              </p>
            </div>
            <div className="flex flex-col sm:mt-[1rem] h-[550px] mt-[12rem] items-center">
              <h1 className="text-[3rem]">
                pIcs
              </h1>
              <div className="flex m-4">
                <img
                  src={xxx}
                  alt=""
                  className="p-1 rounded-[1rem] md:h-[830px]"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
